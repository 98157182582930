import { useState } from 'react';
import { Modal, Form, Button } from 'antd';
import BundleForm from '../forms/BundleForm';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import { CountryPrice, DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';
import { defaultNotify, errorNotify } from '../common/Notify';

interface EditBundleProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bundle: Bundle;
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    updateBundle: UpdateAction<Bundle, void>;
}

const EditBundle: React.FC<EditBundleProps> = props => {
    const { open, setOpen, bundle, defaultPrice, countries, updateBundle } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const getPricesByCountryFromForm = (input: any): CountryPrice[] => {
        if (!input) {
            return [];
        }

        const typed: CountryPrice[] = input.map((countryPrice: any) => ({
            country: countryPrice.country,
            price: +countryPrice.price,
            price_19: +countryPrice.price_19,
        }
        ));
        const countryPricesMap:
            { [key: string]: { price: number, price_19: number } } = typed.reduce((prev, current) => {
                const { country, price, price_19 } = current;
                return { ...prev, [country]: { price, price_19 } };
            }, {});
        const result: CountryPrice[] = Object
            .entries(countryPricesMap).map(([country, { price, price_19 }]) => ({ country, price, price_19 }));

        return result;
    };

    const handleSubmit = async () => {
        const values = form.getFieldsValue();
        const { name, url, gp_url, price, price_19: price19, price_by_country: priceByCountry } = values;

        const pricesByCountry = getPricesByCountryFromForm(priceByCountry);

        const updatedBundle: Bundle = {
            id: bundle.id,
            name: name.trim(),
            url: url.trim(),
            gp_url: gp_url.trim(),
            price: +price,
            price_19: +price19,
            price_by_country: pricesByCountry,
            updated_by: '',
        };
        setConfirmLoading(true);
        try {
            await updateBundle(updatedBundle);
            setOpen(false);
            defaultNotify(`${name} bundle updated`, '');
        } catch (e: any) {
            errorNotify('Error updating bundle', e.message);
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => setOpen(false);

    return (
        <Modal
            title="Edit Bundle"
            getContainer={false}
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button onClick={handleSubmit} key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <BundleForm
                form={form}
                bundle={bundle}
                defaultPrice={defaultPrice}
                countries={countries}
                type={formType.EDIT_FORM}
            />
        </Modal>
    );
};

export default EditBundle;
