import { AxiosError } from 'axios';
import queryString from 'query-string';
import { ErrorReportStats, FetchErrorReportRequest } from '../../models/errorReport';
import { authInterceptor } from './interceptors';
import { ErrorReportAction, ErrorReportActionTypes } from '../types/errorReport';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

// eslint-disable-next-line
export const fetchErrorReport = (req: FetchErrorReportRequest): Action<ErrorReportAction> => {
    const {
        from,
        to,
        region,
        country_code,
        country_by_device,
        rq_bundle,
        v_provider,
        is_general_provider,
        groupBy,
        kind_app,
        traffic_distribute_mode,
        is_200,
        user_category,
        user_consent,
        zone,
        user_ip,
        template_type,
        purposes_consent,
        purposes_li_consent,
        vendors_consent,
        vendors_li_consent,
    } = req;

    const query = queryString.stringify(
        {
            from,
            to,
            region,
            country_code,
            country_by_device,
            rq_bundle,
            v_provider,
            is_general_provider,
            traffic_distribute_mode,
            kind_app,
            is_200,
            user_category,
            user_consent,
            zone,
            user_ip,
            template_type,
            group_by: groupBy,
            purposes_consent,
            purposes_li_consent,
            vendors_consent,
            vendors_li_consent,
        },
        { arrayFormat: 'comma' },
    );

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: ErrorReportActionTypes.FETCH_ERROR_REPORT_REQUEST });
        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<ErrorReportStats>(`reports/errors?${query}`);
            dispatch({ type: ErrorReportActionTypes.FETCH_ERROR_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                ErrorReportActionTypes.FETCH_ERROR_REPORT_ERROR,
                'Error fetching error report',
            );
        }
    };
};
