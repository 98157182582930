import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { Country } from '../../models/country';
import { DefaultPrice } from '../../models/price';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import CountrySelect from '../common/CountrySelect';

export const appGalleryURL = 'https://appgallery.cloud.huawei.com/ag/n/app/';
export const hardcodedHagID = 'C000000000';
export const bundlesWithNoHagID = ['com.huawei.search.banner', 'com.huawei.search.newsfeed'];

type BundleWithHagID = Bundle & {
    hagID?: string;
};

interface BundleFormProps {
    form: FormInstance<Bundle>;
    bundle?: Bundle;
    bundlesNamesList?: string[];
    defaultPrice: DefaultPrice;
    countries: Country[];
    type: formType;
}

const BundleForm: React.FC<BundleFormProps> = props => {
    const { form, bundle, bundlesNamesList, defaultPrice, countries } = props;

    const priceRegex = /^[1-9]\d*$/;
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    useEffect(() => {
        if (bundle) {
            const currentBundle: BundleWithHagID = { ...bundle };
            if (bundlesWithNoHagID.includes(bundle.name)) {
                currentBundle.hagID = hardcodedHagID;
            } else {
                currentBundle.hagID = bundle.url.slice(appGalleryURL.length);
            }
            currentBundle.gp_url = bundle.gp_url;

            form.setFieldsValue(currentBundle);
        } else {
            // Set default values
            form.setFieldsValue({
                name: '',
                hagID: '',
                url: '',
                gp_url: '',
                price: defaultPrice.price,
                price_19: defaultPrice.price,
                price_by_country: [{ country: '', price: defaultPrice.price, price_19: defaultPrice.price }],
            } as BundleWithHagID);
        }
    }, [bundle]);

    const validateName = (rule: any, value: string) => (
        value && bundlesNamesList?.includes(value)
            ? Promise.reject(new Error('Bundle with this name already exists!'))
            : Promise.resolve()
    );

    const nameRules = [
        { required: true, message: 'Please input bundle name!' },
        { validator: validateName },
    ];

    const urlRules = [
        { required: false, message: 'Please input URL!' },
        { pattern: urlRegex, message: 'Please input a valid URL!' },
    ];

    const priceRules = [
        { required: true, message: 'Please input price!' },
        { pattern: priceRegex, message: 'Please input a positive integer number!' },
    ];

    const countryRules = [
        { required: true, message: 'Please select country!' },
    ];

    const shouldUpdate = (prevValues: any, curValues: any) => (
        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
    );

    return (
        <Form
            form={form}
            id={bundle?.id?.toString()}
            layout="horizontal"
            wrapperCol={{ span: '100%' }}
            initialValues={{ name: '', price: defaultPrice.price, price_19: defaultPrice.price }}
        >
            <Form.Item
                label="Name"
                labelAlign="left"
                name="name"
                rules={nameRules}
            >
                <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
                label="URL"
                labelAlign="left"
                name="url"
                tooltip="Enter URL"
                rules={urlRules}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="GP URL"
                labelAlign="left"
                name="gp_url"
                tooltip="Google Play URL"
                rules={urlRules}
            >
                <Input />
            </Form.Item>
            <Row align="middle" wrap={false}>
                <Col style={{ width: '100%' }}>
                    <Form.Item
                        label="Price"
                        labelAlign="left"
                        wrapperCol={{ span: 10 }}
                        name="price"
                        rules={priceRules}
                    >
                        <Input type="number" min="1" />
                    </Form.Item>
                </Col>
                <Col style={{ width: '100%' }}>
                    <Form.Item
                        label="Interstitial price"
                        labelAlign="left"
                        wrapperCol={{ span: 10 }}
                        name="price_19"
                        rules={priceRules}
                    >
                        <Input type="number" min="1" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.List name="price_by_country">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Row key={field.key} justify="start" align="middle">
                                <Row gutter={16} align="middle" style={{ width: '100%' }}>
                                    <Col span={12}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={shouldUpdate}
                                        >
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    style={{ width: '100%', marginTop: 14, marginBottom: 16 }}
                                                    label="Country"
                                                    labelAlign="left"
                                                    labelCol={{ offset: 0 }}
                                                    name={[field.name, 'country']}
                                                    rules={countryRules}
                                                >
                                                    <CountrySelect countries={countries} />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Col>
                                </Row>
                                <Row
                                    gutter={16}
                                    justify="space-around"
                                    align="middle"
                                    wrap={false}
                                    style={{ maxWidth: 400 }}
                                >
                                    <Col>
                                        <Form.Item
                                            {...field}
                                            label="Price"
                                            wrapperCol={{ span: 15 }}
                                            labelCol={{ offset: 0 }}
                                            name={[field.name, 'price']}
                                            fieldKey={[field.key, 'price']}
                                            rules={priceRules}
                                        >
                                            <Input placeholder="Price" type="number" min="1" value={field.name} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            {...field}
                                            label="Interstitial"
                                            wrapperCol={{ span: 15 }}
                                            labelCol={{ offset: 0 }}
                                            name={[field.name, 'price_19']}
                                            fieldKey={[field.key, 'price_19']}
                                            rules={priceRules}
                                        >
                                            <Input
                                                placeholder="Interstitial price"
                                                type="number"
                                                min="1"
                                                value={field.name}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Row>
                        ))}

                        <Form.Item style={{ justifyContent: 'center' }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add country price
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default BundleForm;
