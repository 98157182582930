import { CountryPrice } from './price';

interface Bundle {
    id?: number;
    name: string;
    url: string;
    gp_url: string;
    price: number;
    price_19: number;
    price_by_country?: CountryPrice[];
    updated_at?: string;
    updated_by: string;
}

export enum KindApp {
    'Huawei AdExchange' = 'main',
    HuaweiMixed = 'huaweimixed',
    'Xiaomi Squid' = 'xiaomi',
    'Xiaomi HS News' = 'xiaomihsnews',
    Vivo = 'vivo',
    VivoMixed = 'vivomixed',
    Transsion = 'transsion',
    ORTB = 'ortb',
    OppoMixed = 'oppomixed',
}

export interface BundleWeight {
    id?: number;
    bundle: string;
    country: string;
    weights: Record<string, number>;
    created_at?: string;
    updated_at?: string;
    updated_by: string;
}

export default Bundle;
