import { AxiosError } from 'axios';
import queryString from 'query-string';
import { authInterceptor } from './interceptors';
import { FetchPerformanceReportRequest, PerformanceStats } from '../../models/performance';
import { PerformanceReportAction, PerformanceReportActionTypes } from '../types/performance';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

// eslint-disable-next-line
export const fetchPerformanceReport = (req: FetchPerformanceReportRequest): Action<PerformanceReportAction> => {
    const {
        from, to, region, country, country_by_device, bundle: application,
        kind_app, traffic_distribute_mode, provider,
        user_category, user_consent, zone, user_ip,
        template_type, groupBy, purposes_consent,
        purposes_li_consent, vendors_consent, vendors_li_consent } = req;
    const query = queryString.stringify(
        {
            from,
            to,
            region,
            country,
            country_by_device,
            application,
            kind_app,
            provider,
            traffic_distribute_mode,
            user_category,
            zone,
            user_consent,
            user_ip,
            template_type,
            group_by: groupBy,
            purposes_consent,
            purposes_li_consent,
            vendors_consent,
            vendors_li_consent,
        },
        { arrayFormat: 'comma' },
    );

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<PerformanceStats>(`reports/performance?${query}`);
            dispatch({ type: PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_ERROR,
                'Error fetching performance report',
            );
        }
    };
};
